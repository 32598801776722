<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>我的发票</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="receipt-main-box">
            <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
              <a-tab-pane key="1">
                <span slot="tab">我的发票<a-badge :count="0" /></span>
                <mine v-if="activeKey==='1'"/>
              </a-tab-pane>
              <a-tab-pane key="2">
                <span slot="tab">我的申请<a-badge :count="0" /></span>
                <!-- <xyskjl v-if="activeKey==='2'"/> -->
              </a-tab-pane>
              <a-tab-pane key="3">
                <span slot="tab">我已审核<a-badge :count="0" /></span>
                <!-- <xyskjl v-if="activeKey==='2'"/> -->
              </a-tab-pane>
              <a-tab-pane key="4">
                <span slot="tab">待我审核<a-badge :count="0" /></span>
                <!-- <xyskjl v-if="activeKey==='2'"/> -->
              </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
import mine from './mine'

    export default {
        name:"receipt",
        data() {
            return {
              activeKey: '1'
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
          mine
        },
        computed: {
        },
        created () {       
        },
        methods: {
          changeTab(key) {
            this.activeKey = key
          }
        },
    }
</script>

<style lang="scss" scoped>
.receipt-main-box{
      padding: 0 15px;
      background: #fff;
}
</style>