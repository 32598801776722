<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading" cancelText="取消" okText="保存" width="42.25%" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <!-- <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol"> -->
      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

          <a-radio-group v-else-if="item.type === 'radio'" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio :value="1">
            个人
          </a-radio>
          <a-radio :value="2">
            公司
          </a-radio>

        </a-radio-group>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import editModal from '@/common/mixins/editModal'
import C_ITEMS from '@/utils/items'
import moment from 'moment'

const formData = [
  {
    name: 'company_name',
    label: '发票抬头',
    type: 'input',
    rules: [{ required: true, message: '请输入发票抬头!' }]
  },
  {
    name: 'type',
    label: '发票类型',
    type: 'radio',
    rules: [{ required: true, message: '请选择类型!' }],
    items: {
      data: 'invoiceTypes',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'company_tax',
    label: '企业税号',
    type: 'input',
    rules: []
  },
  {
    name: 'company_telphone',
    label: '联系电话',
    type: 'input',
    rules: [{ required: true, message: '请输入联系电话!' }]
  },
  {
    name: 'company_bank',
    label: '开户银行',
    type: 'input',
    rules: [{ required: true, message: '请输入开户银行!' }]
  },
  {
    name: 'company_no',
    label: '银行账号',
    type: 'input',
    rules: [{ required: true, message: '请输入银行账号!' }]
  },
  {
    name: 'company_address',
    label: '邮寄地址',
    type: 'input',
    rules: [{ required: true, message: '请输入发票邮寄地址!' }]
  },
  {
    name: 'remark',
    label: '发票备注',
    type: 'textarea',
    rules: []
  },
]

export default {
  name: 'EditModal',
  mixins: [ editModal ],

  data() {
    return {
      formData,
      seleteItems: {
        invoiceTypes: C_ITEMS.invoiceTypes,
      }
    }
  },

  async created() {
    this.visible = true
    this.ModalTitle = '编辑发票'
    this.$nextTick()
    let { item, authArr } = this
    if (item) {
      let res = await this.$store.dispatch('financeInvoiceDetailAction', { data: { id: item.id } })
      if (res) {
        item = res.data
        let formFields = {}
        for (let d of formData) {
          if (d.name === 'class_start' || d.name === 'class_end') {
            formFields[d.name] = moment(item[d.name]).format('YYYY-MM-DD')
          } else {
            formFields[d.name] = item[d.name]
          }
        }
        this.form.setFieldsValue(formFields)
      }
    }
  },

  methods: {

    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.id = item.id
          }
          let res = await this.$store.dispatch('financeInvoiceUpdateAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.submitModal()
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
  }
}
</script>
